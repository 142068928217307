import React from "react"
import Layout from "../components/Layout"
import Link from "gatsby-link"
// import Banner from "../components/Banner"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import "../css/information.css"
import prev from "../images/left-triangle-big-circle-x.png"
import next from "../images/right-triangle-big-circle.png"
import bookletLeft from "../images/Booklet-00.png"
import bookletRight from "../images/Booklet-02.png"
import bookletLeft_825 from "../images/Booklet-825-00.png"
import bookletRight_825 from "../images/Booklet-825-06.png"

import booklet_350 from "../images/Booklet-350w-06.png"

import { useMediaQuery } from "react-responsive"

export default ({ data }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)"
  })
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)"
  })
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 414px)"
  })

  return (
    <Layout>
      <SEO title="Page 6" />
      <StyledHero home="true" img={data.images1.childImageSharp.fluid}>
        {/* <img className="LeftArrow" src={prev} alt=""></img> */}

        {!isBigScreen &&
          !isDesktopOrLaptop &&
          isTabletOrMobileDevice &&
          !isMobileDevice && (
            <>
              <Link to="/informationMobile-7">
                <img
                  className="Page"
                  src={bookletRight_825}
                  alt=""
                  useMap="#Right"
                />
                <map name="Right">
                  <area shape="rect" coords="0,1069,824,534" />
                </map>
              </Link>
            </>
          )}
        {!isBigScreen &&
          !isDesktopOrLaptop &&
          isTabletOrMobileDevice &&
          isMobileDevice && (
            <>
              <Link to="/informationMobile-7">
                <img
                  className="Page"
                  src={booklet_350}
                  alt=""
                  useMap="#Right"
                />
                <map name="Right">
                  <area shape="rect" coords="0,1069,824,534" />
                </map>
              </Link>
            </>
          )}
        {/* <img className="RightArrow" src={next} alt=""></img> */}
      </StyledHero>
    </Layout>
  )
}
export const query = graphql`
  query {
    images1: file(relativePath: { eq: "serenity-hero1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
